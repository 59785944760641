.boxplot-widget {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    text-align: center;
    padding: 0 40px;
  }

  // Target nivo wrapper div
  > div:not(.loader) {
    padding-top: 20px;
  }

  .tooltip {
    position: absolute;
    background-color: #fff;
    padding: 6px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 6px;
  }
}