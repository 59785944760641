#zone {
  display: flex;
  width: 100%;
  height: 100vh;
  padding-bottom: 60px;

  .dashboard-list {
    width: 70%;
    overflow-y: auto;

    .dashboard-list-body {
      width: 100%;
      margin: 0 auto;
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .card-list {
        margin: 24px 0;

        .filter-header {
          grid-column: 1 / -1;
        }
      }
    }

    .dashboard-list-header {
      background-color: #ECF0FB;
      display: flex;
      justify-content: space-between;
      padding: 40px;
      min-height: 140px;
      position: relative;

      .dashboard-list-header-title {
        width: 80%;
      }

      .dashboard-list-header-button {
        display: flex;
        align-items: center;
        padding: 8px;
        height: 30px;
        border: none;
        cursor: pointer;
        background-color: #fff;

        span {
          font-size: 14px;
          margin-left: 5px;
        }

      }

      .dashboard-list-header-title {
        
        h2 {
          font-size: 20px;
          font-weight: 900;
          color: #030E18;
          margin-bottom: 20px;
        }

        .description {
          font-size: 14px;
          color: #868686;
          padding-right: 20px;
          margin-bottom: 10px;
        }
      }

      .shared {
        color: rgb(100,100,100);
        position: absolute;
        bottom: 7px;
        right: 9px;
        opacity: 1;
        line-height: 21px;
        font-style: italic;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .dashboard-map {
    width: 30%;
    background-color: rgba(128, 128, 128, 0.226);
    height: 100vh;
  }

  &.tab-displayed {
    margin-top: var(--dashboard-tab-height);
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 1010px) {
  #zone .filter-header .filter-header-buttons {
    flex-direction: column;
  }
}

@media screen and (max-width: 735px) {
  #zone {
    flex-direction: column;

    .dashboard-list {
      width: 100%;
      height: calc(70vh - 36px);
    }

    .dashboard-map {
      width: 100%;
      height: calc(30vh - 36px);
    }
  }
}