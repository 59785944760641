
.multi_time_range {
  display: flex;
  flex-direction: column;

  svg.range-action {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  .preset-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    button {
      border: 1px solid #CCD4E3;
      border-radius: 12px;
      background-color: transparent;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &.selected {
        background-color: #456EFA;
        border: 1px solid #456EFA;
        color: #FFFFFF;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    transition: all 0.15s ease-in;

    p {
      color: #456EFA;
      font-size: 14px;
      font-style: italic;
      margin-top: 10px;

      b {
        font-weight: 900;
      }
    }
  }

  .manual-container {
    margin-top: 16px;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      gap: 9px;

      .header, .range {
        display: flex;
        width: 100%;
        gap: 18px;
        align-items: center;
      }

      .header {
        padding-right: 40px;
        margin-bottom: 5px;

        p {
          color: #8A8A8A;
          font-family: Avenir;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          flex: 1;
        }
      }

      .range {
        .input {
          display: flex;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #E6EAEE;
          background: #FFF;
          padding: 0px 16px;
          height: 56px;
          gap: 15px;
          cursor: pointer;

          input {
            height: 56px;
          }

          input, fieldset {
            cursor: pointer;
            outline: none;
            border: none;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}