.pr-input {
  display: flex;
  flex-direction: column;
  column-gap: 25px;
  width: 100%;
  position: relative;

  .location-icon {
    width: 28.5px;
    height: calc(100% + 10px);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 11px;
    left: -37px;
    padding-top: 10px;

    .on {
      color: #B7C6F8;
      font-size: 28.5px;
      transform: scaleY(0.875);
      margin-bottom: 45px;
      transition: all .15s ease-in;
    }

    .circle {
      width: 15px;
      height: 15px;
      margin-bottom: 9px;
    }

    .rectangle {
      width: 18px;
      height: 18px;
      margin-bottom: 8px;
    }

    .dots {
      height: 100%;
      width: 7px;
      background: url(data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHJvbGU9ImltZyIgd2lkdGg9IjE1IiBoZWlnaHQ9IjEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxjaXJjbGUgY3g9IjMiIGN5PSIzIiByPSIzIiBmaWxsPSIjRDlEOUQ5IiAvPgo8L3N2Zz4KCg==);
      background-repeat: repeat-y;
    }
  }

  input {
    height: 56px;
    font-size: 14px;
    width: 100%;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    padding: 21px 16px;
    border: 1px solid #B6B8BC;
    outline: 1px solid transparent;
    z-index: 2;

    &::placeholder {
      color: #A1A6B0;
    }

    &:focus {
      border: 1px solid #456EFA;
      outline: 1px solid #456EFA;

      &::placeholder {
        color: #456EFA;
      }
    }
  }

  .delete-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    transition: all .15s ease-in;
    color: #F95B64;
    z-index: 3;

    &:hover {
      transform: scale(1.1);
    }
  }

  > span {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #F95B64;
    font-size: 14px;
    font-weight: 500;
    column-gap: 8px;
    transition: all .15s ease-in;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  > .intermediates {
    height: 0;
    padding: 0 0 0 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all .15s ease-in;
  }

  &.selected input {
    border: 1px solid #456EFA;
    outline: 1px solid #456EFA;

      &::placeholder {
        color: #456EFA;
      }
  }

  &.error {
    span {
      height: 19px;
      margin-top: 14px;
      margin-bottom: 2px;
    }

    .location-icon .on {
      margin-bottom: 80px;
    }

    input {
      border: 1px solid #F95B64;
      outline: 1px solid transparent;
  
        &::placeholder {
          color: #F95B64;
        }
    }

    &.selected input {
      outline: 1px solid #F95B64;
    }
  }

  &.expandable .location-icon {
    cursor: row-resize;
  }

  &.expanded .intermediates {
    padding: 24px 0 12px 16px;
  }

  &:hover {
    .delete-icon {
      opacity: 1;
    }
  }
}