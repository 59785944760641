.mapboxgl-hover-custom-popup {
  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 0.4375rem;

    button {
      color: white;
      margin-right: 5px;
      margin-top: 5px;
    }
    .mapbox-popup-title {
      padding: 5px 30px 5px 10px;
      font-size: 0.8em;
      color: var(--color-white);
      border-radius: 0.4375rem 0.4375rem 0 0;
    }
  }

  .mapboxgl-popup-container {
    padding: 5px 10px;
    font-size: 0.8em;
  }
}
