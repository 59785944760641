#dashboards {
  height: calc(100vh - var(--topbar-height));
  margin-top: var(--dashboard-tab-height);
  padding-bottom: var(--dashboard-tab-height);
  overflow-y: scroll;

  .widgets {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    background-color: white;
  }

  .react-grid-layout {
    background-color: white;
    min-height: calc(100vh - var(--topbar-height) - var(--dashboard-tab-height));
  }

  .react-resizable-handle {
    background: none;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      right: 7px;
      bottom: 7px;
      width: 17px;
      height: 17px;
      border-right: 2px solid #003AFF;
      border-bottom: 2px solid #003AFF;
    }
  }
}