.map {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .setting {
    text-align: end;
    padding: 20px 20px 0px 0px;
    
    .button {
      background-color: #456ffadc;

      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .loader {
    position: absolute;
    z-index: 9;
    top: 0;
  }

  &.is-loading {
    .mapboxgl-map {
      opacity: 0.5;
      filter: blur(2px);
    }
  }
}