.legends {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 20px;
  gap: 10px;
  overflow: auto;

  a {
    color: #5e8df1;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .legend {
    display: flex;
    align-items: center;
    gap: 10px;

    .color {
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      border-radius: 50%;
    }
  }
}