.sections-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  margin-bottom: 45px;

  .section {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    > div.label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 29px;

      input {
        border: none;
        font-size: 16px;
        font-weight: 800;
        color: #505B66;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
      }

      svg {
        cursor: pointer;
        font-size: 29px;
        color: #456EFA;
        transition: all .15s ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    > .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
      padding-left: 33px;
    }

    > div.add-intermediate-pr {
      &.add-intermediate-pr {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 18px;
        color: #505B66;
        font-size: 14px;
        font-weight: 800;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:not(:first-of-type)::before {
      content: "";
      border: 1px solid #EDF1FC;
    }
  }
}