.bar-chart-widget {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    text-align: center;
    padding: 0 40px;
  }

  // Target nivo wrapper div
  > div:not(.loader) {
    padding-top: 70px;
  }

  @keyframes barstrokeout {
    from {stroke-width: 0px;}
    to {stroke-width: 15px;}
  }
  
  @keyframes barstrokein {
    from {stroke-width: 15px;}
    to {stroke-width: 0px;}
  }
}