.favorite-icon {
  display: flex;
  align-items: baseline;

  >button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin-left: 8px;
  }

  svg {
    fill: #CAD1E2;
  }

  &.selected {
    svg {
      fill: #F3B038;
    }
  }
}