.map-widget-popup {
  .mapboxgl-popup-content {
    padding: 0px;
    border-radius: 4px;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    overflow: hidden;

    .mapboxgl-popup-close-button {
      right: 13px;
      color: white;
      top: 7px;
      cursor: pointer;
      transform: scale(2);
    }

    .content {
      h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 36px 8px 16px;
        font-family: Avenir;
        font-size: 14px;
        min-height: 30px;
        font-weight: 800;
        background-color: #01080E;
        color: white;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
        background-color: white;
        max-height: 250px;
        overflow-y: auto;

        .section {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .row {
          display: flex;

          span {
            font-family: Avenir;
            font-size: 14px;
            line-height: 14px;
            color: #01080E;

            &.label {
              font-weight: 800;
            }
  
            &.value {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}