.rpPopup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-grey-900);
  padding: 5px 30px 5px 10px;
  font-size: 0.8em;
  color: var(--color-white);
  border-radius: 0.4375rem 0.4375rem 0 0;
}

.rpPopup-body {
  padding: 5px 10px;
  font-size: 0.8em;

  .rpPopup-body-title {
    font-weight: bold;
  }
}