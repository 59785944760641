.widget-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  container: widget;
  container-type: inline-size; 
  border: 2px solid rgb(221, 221, 221);
  overflow: hidden;

  > .info {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 16px;
    font-weight: 400;
    color: white;

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 27px;
      text-decoration: underline;
      background-color: #456EFA;
      padding: 4px 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .parameters {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: white;
      font-size: 14px;
      color: black;
      overflow: hidden;
      transition: all .15s ease;
      border-left: 1px solid #CCD4E3;
      border-right: 1px solid #CCD4E3;
      border-bottom: 1px solid #CCD4E3;
    }

    &.collapsed {
      h3 {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
      .parameters {
        padding-top: 0;
        padding-bottom: 0;
        height: 0;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }
  }

  > div.actions {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 2;
    display: flex;
    gap: 8px;
    opacity: 0;
    transition: all .15s ease;

    button {
      padding: 0;
      width: 40px;
      height: 40px;

      .zone-card-button-icon {
        margin: 0;
      }
    }

    .filter-action-wrapper {
      position: relative;

      > span {
        position: absolute;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        background-color: red;
        border-radius: 50%;
        top: -6px;
        right: -4px;
        color: white;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .children-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }

  &.updatable {
    border: 2px solid #003AFF;
    cursor: grab;

    * {
      pointer-events: none;
    }
  }

  &:hover > div.actions {
    opacity: 1;
  }
}

@container widget (max-width: 619px) {
  .widget-wrapper div.actions {
    top: 5px;
    right: 5px;

    button {
      width: 50px;
      span {
        display: none;
      }

      .zone-card-button-icon {
        margin-right: 0px;
      }
    }
  }
}