.customButton{
  border: 1px solid #22242D;
  background: #FFFFFF;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
  padding: 11px 32px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background: #F2F2F2;
  }

  &.primary {
    border: none;
    background: #456EFA;
    color: #FFFFFF;

    &:hover {
      background: #456ffae1;
    }
  }

  &.secondary {
    border: 1px solid #AA45FA;
    background: #AA45FA;
    color: #FFFFFF;

    &:hover {
      background: #AA45FAe1;
      color: #FFFFFF;
    }
  }

  &.black {
    border: 1px solid #22242D;
    background: #22242D;
    color: #FFFFFF;

    &:hover {
      background: #22242De1;
      color: #FFFFFF;
    }
  }

  &.borderless {
    border: none;
    background: none;
    color: #456ffa;

    &:hover {
      background: #F2F2F2;
    }
  }

  .zone-card-button-icon {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    font-size: 14px;
    line-height: 100%;
    font-weight: 900;
  }
}