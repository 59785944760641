.map-gl {
  height: calc(100vh - 72px);
  width: 100%;

  .setting {
    text-align: end;
    padding: 20px 20px 0px 0px;
    
    .button {
      background-color: #456ffadc;

      span {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}