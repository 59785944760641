.topbar {
  background-color: #01080e;
  height: var(--topbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sncf-banner {
    display: flex;

    .mastheader-logo>a {
      background-color: initial;
      color: #0088ce;

      &>img {
        border-style: none;
        vertical-align: middle;
        width: auto;
        height: 2.25rem;
      }
    }
    .mastheader-logo {
      .pdi {
        height: 30px;
        margin-left: 10px;
      }
    }

    .app-name-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .app-name {
        color: var(--color-white);
        font-weight: 500;
        text-transform: uppercase;
        padding-left: 1rem;
        padding-top: 0.25rem;
        margin: 0;
        line-height: 1.25;
        font-size: 1.2rem;
      }
    }
  }

  button {
    text-transform: capitalize;
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
    font-size: 16px;
  }

  .app-name {
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
  }
}

.menu-item-icon {
  margin-right: 0.5rem;

  &>svg {
    overflow: hidden;
    vertical-align: middle;
  }
}

.menu-item-title {
  padding-top: 1px;
  font-family: Avenir;
  color: var(--color-carbone);
}

@media (max-width: 1280px) {
  .topbar {
    .sncf-banner {
      display: none;
    }

    nav.tab-list {
      margin-left: 0;

      ul {
        margin-left: 0;
      }
    }
  }
}
