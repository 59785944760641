.custom-modal {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-height: 95vh;
  overflow-y: auto;
  width: calc(100vw - 48px);
  min-width: 300px;
  max-width: 550px;

  .custom-modal-header.primary {
    display: flex;
    justify-content: space-between;
  
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-medium-blue-100);
      line-height: 140%;
    }
  
    button {
      border: none;
      background-color: white;
      cursor: pointer;
      font-size: 20px;
      height: 30px;
      color: var(--color-medium-blue-100);
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding: 3px;
      &:hover, &:focus {
        background-color: #F5F6FA;
        border-radius: 50%;
      }

      &:focus {
        border: 2px solid var(--color-medium-blue-100);
        padding: 1px;
      }
    }
  }

  .custom-modal-header.secondary {
    display: flex;
    justify-content: space-between;
  
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-purple);
      line-height: 140%;
    }
  
    button {
      border: none;
      background-color: white;
      cursor: pointer;
      font-size: 20px;
      color: var(--color-purple);
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding: 3px;
      &:hover, &:focus {
        background-color: #F5F6FA;
        border-radius: 50%;
      }

      &:focus {
        border: 2px solid var(--color-purple);
        padding: 1px;
      }
    }
  }
}
