.action-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 21px;
  margin-top: auto;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    color: #456EFA;
    font-size: 14px;
    padding-right: 10px;
  }

  .simple-button {
    height: 56px;
  }
}