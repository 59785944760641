.custom-button-check {
  .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    > button {
      max-width: 230px;
      border: 1px solid #CCD4E3;
      border-radius: 12px;
      background-color: transparent;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-right: 5px;
      text-transform: capitalize;
      cursor: pointer;
  
      &:last-child {
        margin-right: 0;
      }
  
      &.selected {
        background-color: #456EFA;
        border: 1px solid #456EFA;
        color: #FFFFFF;
      }
    }
  }
}