* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;

  body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #app {
      overflow: hidden;
      height: calc(100vh - var(--topbar-height));
      margin-top: var(--topbar-height);
      background: #F3F5FA;
    }
  }
}

// Common styles

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-style {
  border: none;
  background: none;
  outline: none;
  list-style: none;
  user-select: none;
}

// MUI overrides

.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}
