.dashboard-tabs {
  position: fixed;
  top: var(--topbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--dashboard-tab-height);
  width: 100%;
  background-color: #222B36;
  z-index: 1;
  overflow-x: auto;
  overflow-y: hidden;

  .list {
    display: flex;
    flex-shrink: 0;

    > div:first-of-type {
      width: 90px;
      height: var(--dashboard-tab-height);
      cursor: pointer;
      transition: all .15s ease-out ;
      
      svg {
        font-size: 29px;
        color: rgba(255, 255, 255, 0.50);
      }

      &:hover {
        background-color: white;

        svg {
          color: #003AFF;
        }
      }

      &.home {
        cursor: auto;
        transition: none;
        background-color: white;

        svg {
          color: #01080E;
        }

        &:hover {
          svg {
            color: #01080E;
          }
        }
      }
    }

    .tab {
      height: var(--dashboard-tab-height);
      font-size: 14px;
      padding: 14px 16px;
      color: white;
      gap: 15px;

      &.selected {
        color: #01080E;
        background-color: white;
      }

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      svg {
        font-size: 17px;
        cursor: pointer;
        transition: all .15s ease-out;

        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    > button {
      height: 38px;
      padding: 12px;
      margin-right: 23px;
      color: white;
      border-radius: 4px;
      border: 1px solid white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    > div {
      width: var(--dashboard-tab-height);
      height: var(--dashboard-tab-height);
      padding: 10px;
      background-color: rgb(103, 111, 123);
      color: #eee;
      transition: all .15s ease-out ;
      cursor: pointer;

      &.selected {
        color: #003AFF;
        background-color: white;
      }

      &:hover {
        color: #003AFF;
        background-color: white;
      }
    }
  }
}