.card-list-item {
  list-style-type: none;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #E2E8F5;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
}