#updateZonePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    position: fixed;
    width: 100%;
    height: var(--topbar-height);
    top: 0;
    left: 0;
    background-color: #456EFA;
    z-index: 1001;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 35px;

    svg {
      color: white;

      &.close-icon {
        cursor: pointer;
        transition: all .1s ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    div:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      column-gap: 27px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      min-width: 230px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    div.simple-button {
      width: unset;
      height: 46px;
      margin: 0 auto;
    }
  
    label.toggle-bv {
      flex-shrink: 0;
      background-color: #fff;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }

  .content {
    display: flex;
    height: 100%;

    .panel {
      flex: 1;
      background-color: white;
      max-width: 448px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      overflow-y: auto;
    }

    .map {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: gray;
    }
  }
}

@media screen and (max-aspect-ratio: 5/6) {
  #updateZonePage {
    .header > div:first-of-type {
      min-width: unset;
      
      > span {
        display: none;
      }
    }

    .content {
      flex-direction: column;

      .panel {
        max-width: unset;
      }

      .map .map-gl {
        height: calc(50vh - 72px);
      }
    }
  }
}