
.multi_input_range {
  position: relative;
  display: flex;
  flex-direction: column;
  
  > svg.range-action {
    position: absolute;
    top: -33px;
    right: 0;
  }

  svg.range-action {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    gap: 9px;

    .header, .range {
      display: flex;
      width: 100%;
      gap: 18px;
      align-items: center;
    }

    .header {
      padding-right: 40px;
      margin-bottom: 5px;

      p {
        color: #8A8A8A;
        font-family: Avenir;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        flex: 1;
      }
    }

    .range {
      .input {
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #E6EAEE;
        background: #FFF;
        height: 56px;
        gap: 15px;
        width: 100%;

        input {
          outline: none;
          border: none;
          background: none;
          height: 56px;
          width: 100%;
          padding: 0px 16px;
        }
      }
    }
  }
}