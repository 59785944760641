#zone-page {
  width: 100%;
  height: calc(100vh - var(--topbar-height));
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .card-list {
    margin: 24px 0;

    .filter-header {
      grid-column: 1 / -1;
    }
  }
}