.MuiBackdrop-root.MuiModal-backdrop:not(.MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-board {
  display: flex;
  flex-direction: column;

  .form-group {
    margin-top: 15px;

    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #AA45FA;
      }
    }
  
    .label {
      margin-bottom: 10px;
      color: #8A8A8A;
    }
  }

  .checkbox {
    margin-top: 15px;
    .checkbox-input {
      &.Mui-checked {
        color: #AA45FA;
      }
    }
  }
  
  .button {
    margin-top: 25px;
    height: 50px;
  }
}
