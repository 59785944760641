.MuiBackdrop-root.MuiModal-backdrop:not(.MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-zone {
  display: flex;
  flex-direction: column;

  .modal-zone-form-group {
    margin-top: 15px;
  
    .modal-zone-form-group-label {
      margin-bottom: 10px;
      color: #8A8A8A;
    }
  }

  .modal-zone-checkbox {
    margin-top: 15px;
  }

  .modal-zone-text {
    margin-top: 15px;
    text-align: center;
  }
  
  .modal-zone-button {
    margin-top: 25px;
    height: 50px;
  }
  .modal-zone-button-kind {
    margin-top: 15px;
    height: 50px;
  }
}

.modal-zone-share {
  .select-zone-share-form {
    margin-top: 20px;
  }

  .select-zone-share-checkbox {
    color: #c6c6c6;

    &.Mui-checked {
      color: #456EFA;
    }
  }

  .modal-zone-share-button {
    margin-top: 40px;
  }

  .select-zone-share-form {
    .select-zone-share-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
    }
    .modal-zone-share-chekbox {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 10px;
    }
    .select-zone-share-select {
      border: none;
      background-color: white;
    }
  }
}
