.custom-select-check {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  overflow: unset;
  transition: all .2s ease-out;
  pointer-events: all;

  .input {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    > svg {
      position: absolute;
      pointer-events: none;
  
      &:first-of-type {
        left: 12px;
      }

      &:last-of-type {
        right: 12px;
      }
    }

    > input {
      border-radius: 4px;
      border: 1px solid #CCD4E3;
      background: white;
      width: 100%;
      height: 56px;
      padding: 10px 42px;
    }
  }

  .results {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: unset;
    bottom: unset;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 999;
    max-height: 254px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06), 3px 4px 4px 0px rgba(0, 0, 0, 0.08);
    transition: opacity .2s ease-out;

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      outline: none;
      border: none;
      background: none;
      height: 36px;
      cursor: pointer;
      transition: all .1s ease-out;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      .MuiCheckbox-root {
        svg {
          fill: #CCD4E3;
          width: 20px;
          height: 20px;
        }

        &.Mui-checked {
          svg {
            fill: #456EFA;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;

        &:first-of-type {
          font-weight: 800;
        }

        &:last-of-type {
          font-weight: 400;
        }
      }

      &:hover {
        background-color: rgba(69, 110, 250, 0.10);
        color: #456EFA;
      }

      &:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}