.input-text {
  display: flex;
  flex-direction: column;

  .input {
    height: 56px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px 12px;
    font-size: 16px;
    font-weight: 400;
    color: #797979;

    &:hover {
      border-color: black;
    }

    &:focus {
      border-color: #1976d2;
      border-width: 2px;
    }
  }
}