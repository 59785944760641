#access-denied {
  height: 100vh;
  font-family: Avenir;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .warning-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 50%;
    max-width: 50%;
    min-height: 50%;
    max-height: 50%;

    .warning-icon {
      margin: 1rem;
      height: 100px;
      width: auto;
    }

    .main-message {
      font-size: 2.25rem;
      font-weight: 900;
    }

    .button-wrapper {
      position: absolute;
      width: 100vw;
      bottom: 40px;
    }
  }
}