.points-wrapper {
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 45px;

  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding-left: 33px;

    > div {
      display: flex;
      align-items: center;
      gap: 12px;

      > svg {
        align-self: flex-start;
        margin-top: 12px;
        cursor: pointer;
        font-size: 29px;
        color: #456EFA;
        transition: all .15s ease-in;

        &:hover {
          transform: scale(1.1);
        }
      }

      &:not(:first-of-type) {
        padding-top: 20px;
        border-top: 1px solid #E0E0E0;
      }
    }
  }
}