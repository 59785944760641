.zone-card-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .zone-card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .zone-card-header-first-section {
      display: flex;

      h2 {
        max-width: 190px;
        font-size: 20px;
        min-height: 48px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .zone-card-header-first-section-verified-icon {
      color: var(--color-medium-blue-100);
      margin-left: 5px;
    }
  }

  .zone-card-body {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description {
      color: #4B4B4B;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      overflow: hidden;
      min-height: 65px;
      margin-bottom: 24px;
    }

    .modify-date {
      color: #B7B7B7;
      font-style: italic;
      margin-top: auto;
    }

    .thumbnail-wrapper {
      margin-bottom: 24px;
      margin-top: 12px;
      height: 88px;

      .thumbnail {
        height: 82px;
        position: absolute;
        width: 250px;
        bottom: 100px;
        left: 24px;
        border-radius: 4px;
        object-fit: cover; 
        object-position: center;
        cursor: pointer;
        transition: all .1s ease-in;

        &:hover {
          transform: scale(1.05);
        }

        &.displayed {
          height: 100%;
          bottom: 0;
          width: 300px;
          left: 0;

          &:hover {
            transform: none;
          }
        }
      }
    }

    .zone-card-button {
      width: 100%;

      .zone-card-button-item {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 64px;
        padding: 11px 32px;
        cursor: pointer;
        background: #456EFA;
        color: #FFFFFF;
        font-size: 14px;

        &:hover {
          background: #456ffae1;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .shared {
    position: absolute;
    bottom: 2px;
    right: 7px;
    opacity: 0.4;
    font-size: 11px;
    font-weight: 600;
  }
}