.filterDashboardModal {
  .label {
    color: #272727;
    font-weight: 900;
    font-size: 16px;
  }

  .numCirculation {
    margin-bottom: 16px;
  }
  
  .breakLine {
    border-bottom: 1px solid #CCD4E3;
    width: 100%;
    margin: 30px 0;
  }

  .filter-widget {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .date-picker {
    display: flex;

    > div {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      > div {
        flex: 1;
      }
    }
  }

  .filter-widget-buttons {
    margin-top: 30px;
    text-align: center;

    .reset-button {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #272727;
      margin-bottom: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}