.dashboard-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .first-section {
      display: flex;

      h2 {
        max-width: 190px;
        font-size: 20px;
        min-height: 48px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .verified-icon {
      color: var(--color-medium-blue-100);
      margin-left: 5px;
    }
  }

  .body {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .description {
      color: #4B4B4B;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 65px;
      margin-bottom: 24px;
    }

    .modify-date {
      color: #B7B7B7;
      font-style: italic;
      margin-top: auto;
      margin-bottom: 12px;
    }

    .button {
      width: 100%;

      .button-item {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 64px;
        padding: 11px 18px;
        cursor: pointer;
        background: #AA45FA1A;
        color: var(--color-black-100);
        font-size: 14px;

        .button-icon {
          font-size: 16px;
        }

        &:hover {
          background: var(--color-purple-100);
          color: var(--color-white);
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }
}