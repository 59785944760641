.filter-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;

  .filter-header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .filter-header-button-favorite {
      display: flex;

      > button {
        border: 1px solid #CCD4E3;
        border-radius: 12px;
        background-color: transparent;
        padding: 10px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-right: 16px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.selected {
          background-color: #456EFA;
          border: 1px solid #456EFA;
          color: #FFFFFF;
        }
      }
    }

    .filter-header-search-wrapper {
      display: flex;
      gap: 20px;

      .search-bar {
        display: flex;
        align-items: center;
        width: 296px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #E6EAEE;
        padding: 15px;
        border-radius: 24px;
  
        input {
          all: unset;
          margin-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;  
          color: var(--color-near-black);
        }
      }
    }
  }

  .filter-header-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .filter-header-infos-all, .filter-header-infos-sort {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.01em;

      .label {
        color: #868686;
        margin-right: 0.5ch;
      }

      button {
        all: unset;
        cursor: pointer;
        display: flex;

        &.sort-direction {
          color: #868686;
          margin-left: 5px;

          svg {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    .loader {
      width: 100px;
    }
  }
}

@media screen and (max-width: 675px) {
  .filter-header .filter-header-buttons {
    flex-direction: column;
  }
}

@media screen and (max-width: 485px) {
  .filter-header .filter-header-buttons .filter-header-search-wrapper{
    flex-direction: column;
  }
}