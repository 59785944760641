.modal-create-widget {
  display: flex;
  flex-direction: column;
  gap: 34px;
  position: relative;
  overflow: visible;
  max-width: 500px;
  transition: all .15s ease;

  .custom-modal-content {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .form-group {
      gap: 14px;
      margin-bottom: 18px;

      &.date-picker {
        display: flex;

        > div {
          display: flex;
          justify-content: space-between;
          gap: 24px;

          > div {
            flex: 1;
          }
        }
      }

      &.metric-picker > div {
        gap: 24px;

        div.picker {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          width: 100%;
          max-width: 148px;
          height: 148px;
          padding: 18px 16px 12px;
          border-radius: 4px;
          border: 1px solid #CCD4E3;
          text-align: center;
          cursor: pointer;
          transition: all .15s ease;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          img {
            width: 50px;
            height: 50px;
          }

          &:hover {
            border: 1px solid #456EFA;
            box-shadow: -4px 4px 9px 0px rgba(0, 0, 0, 0.10);
          }

          &.selected {
            color: white;
            background-color: #456EFA;
            font-weight: 800;
            
            img {
              // Make the image white
              filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0) brightness(1000%) contrast(1000%);
            }
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.3;
            pointer-events: none;

            &:hover {
              border: 1px solid #CCD4E3;
              box-shadow: none;
            }
          }
        }
      }

      &.date {
        width: calc(50% - 12px);
        margin-right: 12px;
        display: inline-flex;

        > label {
          display: block;
        }

        + .date {
          margin-right: 0;
          margin-left: 12px;
        }
      }

      &.bool {
        flex-direction: row;
        align-items: center;
      }

      &.has-info {
        margin-bottom: 10px;
      }
    }

    > .metric-info {
      font-size: 14px;
      font-style: italic;
      margin-bottom: 18px;
    }

    > button {
      height: 56px;
      margin-top: 14px;
    }
  }

  .preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transform: translateX(100%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #DEE4EF;
    transition: all .15s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 0;
    overflow: hidden;

    > p {
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
      font-weight: 500;
      background-color: #456EFA;
    }

    > div, img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      height: 500px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.06);
    }

    .cancel-preview-button {
      position: absolute;
      width: 150px;
      top: calc(50% + 75px);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.metric-loading {
    .form-group {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.preview-displayed {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    right: 325px;

    .preview {
      padding: 0 40px;
      width: 650px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .modal-create-widget.preview-displayed {
    max-width: 450px;
    right: 250px;

    .preview {
      width: 500px;
    }
  }
}

@media screen and (max-width: 955px) {
  .modal-create-widget.preview-displayed {
    max-width: 390px;
    right: 200px;

    .preview {
      width: 400px;

      > div {
        height: 370px;
      }
    }

    .form-group.metric-picker > div {
      flex-direction: column;
  
      div.picker {
        max-width: unset;
        justify-content: center;
        align-items: center;
        height: unset;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .modal-create-widget.preview-displayed {
    max-width: 100%;
    right: 0;
    border-radius: 8px;

    .preview {
      width: 0;
      padding: 0;
    }
  }
}

@media screen and (max-width: 515px) {
  .modal-create-widget .custom-modal-content .form-group.metric-picker > div {
    flex-direction: column;

    div.picker {
      max-width: unset;
    }
  }
}